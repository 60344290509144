import React, {
    useEffect,
    useState
} from "react";
import { requiredErrormessage,
   TextSpaceonlyValidation,Notifyalert,
    Url,
    
    CommonNotify,
    UrlSplitter,checkEmptyUndefined,redirectPage
} from './../../Api.js';
import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";
import Footer from "../../includes/Footer";
const General = () => {



 
    const [roleid] = useState(UrlSplitter(4));
    const [name, setName] = useState("");
    const [permissions, setPermissions] = useState("");
     const[status, setStatus] = useState(1); 

    const [namereqerror, setnamereqerror] = useState("");


       
        const Getrole = async (e) => {
            try {
                var postdata = {
                    _id: roleid
                };
                const response = await fetch(Url + "getrole", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            var roledata = data.data;
                            setName(roledata.name);
                            setStatus(roledata.status);
                            setPermissions(JSON.parse(roledata.permissions));
                            setSelectedCheckboxes(JSON.parse(roledata.permissions));
                        } else {
                            //   
                        }
                    })
            } catch (error) {
                //
            }
        }
        const Updaterole = async (e) => {
              var   validationerror = 0
             if(checkEmptyUndefined(name)=== false       ){
                setnamereqerror(requiredErrormessage);
                validationerror = 1  
            }

            if(validationerror == 0){ 

            const form = document.querySelector('form');
            const data = new FormData(form);

            var inputdata = {};
            data.forEach(function(value, key) {
                inputdata[key] = value;
            });
            
             var  statusupdate = (typeof status == 'undefined'  || status == ''|| status == '1')?'1':0;
 
                 var postdata = {
                    _id: roleid,
                    name: inputdata.name,
                    permissions: inputdata,
                    status:statusupdate
 
                };
                 
                const response = await fetch(Url + "updaterole", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                                                             Notifyalert('success',data.message)

                            redirectPage('roles')
                        } else {
                                                              Notifyalert('error',data.message)

                        }
                    })

                }
           
        };
        const handleRoleInput = (e) => {
             var name = e.target.name;
            var value = e.target.value;
            switch (name) {
                case 'name':
                setName(value);
                break;
                case 'status':
                setStatus(value);
                break;      
               
               
            }
        }
        useEffect(() => {
            Getrole(roleid);
         }, []);



const checkValuePresentorNot=(value)=>{
    //alert(value)

    if(typeof permissions[value] !== 'undefined'  && permissions[value] == '1'){
         return 'checked'
    }

    

   
}

const  setSelectedCheckboxes=async(permissions)=>{
 
  Object.keys(permissions).forEach(function(key) {
   var checkbox = document.querySelector('input[name='+key+']');
    if( typeof checkbox!== 'undefined' && checkbox != null  ){ 


     if (typeof permissions[key] !== 'undefined'  && permissions[key] == '1')
{
      checkbox.checked = (typeof permissions[key] !== 'undefined'  && permissions[key] == '1') ? true : false;

}

    }
 });
 
 
}
    

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit Role":"Add Role"} />

         <Sidebar activePage="Roles" activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit Role":"Add Role"}/>
                              <div className="row match-height">
            <div className="col-md-9 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit Role":"Add Role"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" id="roles-form">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Name</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={name}  type="text" name="name" placeholder="Name"  
                onChange={(e) => handleRoleInput(e)}
                 />
                 <p className="req-error-message">{namereqerror}</p>

                        </div>
<table className="table table-bordered" cellpadding="5" cellspacing="5">
   <thead>
      <tr>
         <td>  Name  </td>
         <td>  View</td>
         <td>Add </td>
         <td>  Edit</td>
         <td> Delete </td>
      </tr>
   </thead>
   <tbody>
      <tr>
         <td> Dashboard</td>
         <td><input type="checkbox"     name="dashboard_view" value="1" /> </td>
         <td colspan="3"> </td>
      </tr>
      <tr>
         <td> Settings</td>
         <td><input type="checkbox"     name="settings_view" value="1" /> </td>
         <td  > </td>
         <td><input type="checkbox"    name="settings_edit" value="1" /> </td>
         <td  > </td>
      </tr>
      <tr>
         <td>Admin User</td>
         <td><input type="checkbox"      name="adminusers_view" value="1" /> </td>
         <td  ><input type="checkbox"     name="adminusers_add" value="1" /> </td>
         <td><input type="checkbox"    name="adminusers_edit" value="1" /> </td>
         <td  > <input type="checkbox"     name="adminusers_delete" value="1" /></td>
      </tr>


     <tr>
         <td> Customers</td>
         <td><input type="checkbox"      name="customers_view" value="1" /> </td>
         <td  > <input type="checkbox"      name="customers_add" value="1" />   </td>
         <td> <input type="checkbox"      name="customers_edit" value="1" />  </td>
         <td  >  <input type="checkbox"      name="customers_delete" value="1" />   </td>
      </tr>

        <tr>
         <td> CMS</td>
         <td><input type="checkbox"      name="cms_view" value="1" /> </td>
         <td  >  </td>
         <td><input type="checkbox"    name="cms_edit" value="1" /> </td>
         <td  >  </td>
      </tr>

       <tr>
         <td> Email Templates</td>
         <td><input type="checkbox"      name="emailtemplates_view" value="1" /> </td>
         <td  >  </td>
         <td><input type="checkbox"    name="emailtemplates_edit" value="1" /> </td>
         <td  >  </td>
      </tr>


      

      <tr>
         <td> Bookings</td>
         <td><input type="checkbox"      name="bookings_view" value="1" /> </td>
         <td  >  </td>
         <td> </td>
         <td  >  </td>
      </tr>


      <tr>
         <td> Enquiries</td>
         <td><input type="checkbox"      name="enquiries_view" value="1" /> </td>
         <td  >  </td>
         <td> </td>
         <td  >  </td>
      </tr>


      
     <tr>
         <td> Blog category</td>
         <td><input type="checkbox"      name="blogcategory_view" value="1" /> </td>
         <td  > <input type="checkbox"      name="blogcategory_add" value="1" />   </td>
         <td> <input type="checkbox"      name="blogcategory_edit" value="1" />  </td>
         <td  >  <input type="checkbox"      name="blogcategory_delete" value="1" />   </td>
      </tr>



     <tr>
         <td> Blog</td>
         <td><input type="checkbox"      name="blog_view" value="1" /> </td>
         <td  > <input type="checkbox"      name="blog_add" value="1" />   </td>
         <td> <input type="checkbox"      name="blog_edit" value="1" />  </td>
         <td  >  <input type="checkbox"      name="blog_delete" value="1" />   </td>
      </tr>

     

       <tr>
         <td> Service Categories</td>
         <td><input type="checkbox"      name="categories_view" value="1" /> </td>
         <td  > <input type="checkbox"      name="categories_add" value="1" />   </td>
         <td> <input type="checkbox"      name="categories_edit" value="1" />  </td>
         <td  >  <input type="checkbox"      name="categories_delete" value="1" />   </td>
      </tr>

    

       <tr>
         <td> Service Subcategories</td>
         <td><input type="checkbox"      name="subcategories_view" value="1" /> </td>
         <td  > <input type="checkbox"      name="subcategories_add" value="1" />   </td>
         <td> <input type="checkbox"      name="subcategories_edit" value="1" />  </td>
         <td  >  <input type="checkbox"      name="subcategories_delete" value="1" />   </td>
      </tr>


        <tr>
         <td> Services</td>
         <td><input type="checkbox"      name="services_view" value="1" /> </td>
         <td  > <input type="checkbox"      name="services_add" value="1" />   </td>
         <td> <input type="checkbox"      name="services_edit" value="1" />  </td>
         <td  >  <input type="checkbox"      name="services_delete" value="1" />   </td>
      </tr>

 

      <tr>
         <td> Notifications</td>
         <td><input type="checkbox"      name="notifications_view" value="1" /> </td>
         <td  > <input type="checkbox"      name="notifications_add" value="1" />   </td>
       </tr>
   


   </tbody>
</table>
<div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleRoleInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>
                         <div className="col-sm-12 d-flex justify-content-end">
                            <a   className="btn btn-primary me-1 mb-1" onClick={Updaterole}>Submit</a>
                         </div>
                       


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default General;