import React, { useState ,useEffect} from "react";
 
 import {baseUrl,Url,CommonNotify} from './../Api.js';

import Chart from 'react-apexcharts'

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";


import Footer from "../includes/Footer";



 
const Dashboard = () => {


 
        const [jobsCount, setjobsCount] = useState(1);
 
       const [earnings, setearnings] = useState(1);

 

  


    //    const [clientdata,setclientdata] =useState({ options: {
    //     chart: {
    //       id: 'apexchart-example'
    //     },
    //     xaxis: {
    //       categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
    //     },
    //       colors: ['#000' ],
    //   },

    //   series: [{
    //     name: 'series-1',
    //     data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
    //   }]
    // })
       
    //       const [orderdata,setorderdata] =useState({ options: {
    //     chart: {
    //       id: 'apexchart-example'
    //     },
    //     xaxis: {
    //       categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
    //     },
    //       colors: ['#000' ],
    //   },

    //   series: [{
    //     name: 'series-1',
    //     data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
    //   }]
    // })


  const GetDashboard = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)
 
 

        const response = await fetch(Url+"dashboard", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var counts = data.data;
             
 
 
          } else{
           }

           
       })


 
  };
 
 
 
          useEffect(() => {
   GetDashboard();
  }, []);
   
  
  
  return (

      <div id="app">
            <Header title="Dashboard" />

         <Sidebar  activePage="Dashboard" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                <Pagetitle  activePage="Dashboard"  />


                    <section className="section">


                       <div className="row mb-2">

                          <div className="col-12 col-md-3">
                             <div className="card card-statistic">
                                <div className="card-body p-0">
                                   <div className="d-flex flex-column">
                                      <div className='px-3 py-3 d-flex justify-content-between'>
                                         <h3 className='card-title'> <i className="fa fa-user"></i> Jobs</h3>
                                         <div className="card-right d-flex align-items-center dashboard-card">
                                            <p>{jobsCount}</p>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>


                          
 


                          <div className="col-12 col-md-3">
                             <div className="card card-statistic">
                                <div className="card-body p-0">
                                   <div className="d-flex flex-column">
                                      <div className='px-3 py-3 d-flex justify-content-between'>
                                         <h3 className='card-title'> <i className="fa fa-user"></i> Earnings</h3>
                                         <div className="card-right d-flex align-items-center dashboard-card">
                                            <p>{earnings}</p>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>

                       </div>
 
 


                    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Dashboard;