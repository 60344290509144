import React, {    useState} from "react";
 import {SiteLogo,   baseUrl,    Url,    Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined} from './../Api.js';
  

const Forgotpassword = () => {
          Redirectdashboard();


       
       
        const [notification, setnotification] = useState("");
        const [notificationtype, setnotificationtype] = useState("");

        const [email, setEmail] = useState("");
        const [emailError, setEmailerror] = useState(false);
        const [emailvalid, setEmailvalid] = useState("");
        const [formValid, setformValid] = useState(true);
        const [validationSubmit, setvalidationSubmit] = useState(false);

         const [submitLoading, setsubmitLoading] = useState(false);

    const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'email':
            setEmail(value);
            setEmailvalid(true);
            validEmailRegex.test(value) ? setEmailerror(false) : setEmailerror(true)
            break;
         
      }
      (email != ''  ) ? setformValid(true): setformValid(false)
   }

        const Resetpassword = async (e) => {
            e.preventDefault();
 
            setsubmitLoading(true)
            if(checkEmptyUndefined(email) === false  ){
            setnotificationtype('error')
            setnotification('* Fields Required')
            setsubmitLoading(false)
            return false
            }



            var postdata = {
                email: email, url:baseUrl
            };
            try {
                const response = await fetch(Url + "vendor-portal-reset-password", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                             setnotificationtype('success')
                               setnotification(data.message)
                        } else {
                            setnotificationtype('error')
                            setnotification(data.message)
                        }
                    })
                    setsubmitLoading(false)
            } catch (error) {
                setnotificationtype('error')
                setnotification(error)
            }
        };
      


  return (
 
    <div id="auth">
 
    <div className="container">
    <div className="row">
        <div className="col-md-5 col-sm-12 mx-auto">
            <div className="card py-4">
                <div className="card-body">
                    <div className="text-center mb-5">
                        <img src={SiteLogo} height="48" className='mb-4'/>
                        <h3>Forgot Password</h3>
                        <p>Please enter your email to receive password reset link.</p>
                    </div>
                    <form action="" autocomplete="off">
                        <div className="form-group">
                            <label htmlFor="first-name-column">Email<span className="field-required">*</span></label>
                              <input  className="form-control"  type="email" name="email" placeholder="Username@gmail.com" value={email}
                              onChange={(e) => handleUserInput(e)}
                              required/>

                               <div className="clearfix">
                                 
                                <a href={baseUrl }   className='float-end'>
                                    <small>Go back to Login</small>
                                </a>
                            </div>

                        </div>
                        { (!emailError && formValid  && submitLoading === false) ?  (
                        <div className="clearfix">
                            <button className="btn btn-primary float-end" onClick={Resetpassword}>Send</button>
                        </div> ) : ''}

                         {
                             (notification!=='') ?  (
                              CommonNotify(notification,notificationtype)
                            ) :'' 
                         }

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

</div>
    
 
  );
};
export default Forgotpassword;