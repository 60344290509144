import React, { useState ,useEffect} from "react";
 
 import {
    Link,
    useNavigate
} from "react-router-dom";

 
import {Lang} from "../Languages.js"

import {Redirectlogin, Siteinfo , frontUrl,SiteFavicon} from '../Frontapi.js';

import Header from "../includes/Header";
import Navbar from "../includes/Navbar";
import Footer from "../includes/Footer";




 

 

const services =   Siteinfo[1]['servicedata'];


 
const Home = () => {
  const navigate = useNavigate();

 
 

   useEffect(() => {
  //   checkss()
  //   Redirectlogin();
  }, []);


  return (

      <div id="app">

      <Header />

    


<div className="container">
        <div className="col-md-12 col-sm-12 col-xs-12 banner paddingzero">
            <div className="col-md-6 col-sm-6 col-xs-12 paddingzero">
                <h1>Home services at your doorstep</h1>
                <div className="banner-border"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                <div className="normal-box">
                    <div className="what-are-you-looking-for">
                        <h5>What are you looking for?</h5>
                        <div className="col-md-12 col-sm-12 col-xs-12 box-top paddingzero">
                            <a className="col-md-6 col-sm-6 col-xs-12 ">
                                <div className="normal-box-shadow">
                                <span>AC & Appliance Repair</span>
                                <img src={frontUrl+'assets/images/frontend/ac-image.png'}/>
                                </div>
                            </a>
                            <a className="col-md-6 col-sm-6 col-xs-12">
                                <div className="normal-box-shadow">
                                <span>Native Water Purifier</span>
                                <img src={frontUrl+'assets/images/frontend/water-purifier-image.png'}/>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 banner-img">
                <img src={frontUrl+'assets/images/frontend/home-banner.png'}/>
            </div>
        </div>
    </div>

<div className="services-background">
    <div className="container">
        <div className="col-md-12 col-sm-12 col-xs-12 services-icons">

         {(services).map((service)=> ( 


                <a className="col-md-2 col-sm-2 col-xs-12">
                <img src={service.image}/>
                <p>{service.name}</p>
            </a>

            ))}

           
        </div>
    </div>
</div>

<div className="container">
    <div className="col-md-12 col-sm-12 col-xs-12 add-banner">
        <div className="col-md-4 col-sm-4 col-xs-12">
            <img src={frontUrl+'assets/images/frontend/add-01.png'}/>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12">
            <img src={frontUrl+'assets/images/frontend/add-02.png'}/>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12">
            <img src={frontUrl+'assets/images/frontend/add-03.png'}/>
        </div>
    </div>
</div>




      <Footer />

 
   </div>
 
  );
};
export default Home;