import React, { useState } from "react";
  import {vuserId,Url,CommonNotify,checkEmptyUndefined,Notifyalert,requiredErrormessage} from './../Api.js';


import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";

import Footer from "../includes/Footer";
 
const Changepassword = () => {

 
 
  const [currentpassword, setCurrentpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");
  
 
 const [currentpasswordreqerror, setcurrentpasswordreqerror]= useState("");
 const [newpasswordreqerror, setnewpasswordreqerror]= useState("");
 const [confirmpasswordreqerror, setconfirmpasswordreqerror]= useState("");


const handleUserInput = (e) => {
     var name = e.target.name;
    var value = e.target.value;

    switch (name) {
        case 'currentpassword':
            setcurrentpasswordreqerror('')
              setCurrentpassword(value);
             break;
        case 'newpassword':
            setnewpasswordreqerror('')
            setNewpassword(value);
            break;
       case 'confirmpassword':
        setconfirmpasswordreqerror('')
           setConfirmpassword (value );
            break;
          
             default :
              break;
    }


 }


  const Updatepassword = async (e) => {
var validationerror=0;
setcurrentpasswordreqerror('')
setnewpasswordreqerror('')
setconfirmpasswordreqerror('')


 if(checkEmptyUndefined(currentpassword) == false){

        setcurrentpasswordreqerror(requiredErrormessage)
        validationerror=1;
    }
     if(checkEmptyUndefined(newpassword) == false){

        setnewpasswordreqerror(requiredErrormessage)
        validationerror=1;
    }
     if(checkEmptyUndefined(confirmpassword) == false){

        setconfirmpasswordreqerror(requiredErrormessage)
        validationerror=1;
    }

if(validationerror == 0){ 
 
      var postdata={userid:vuserId, currentpassword:currentpassword,newpassword:newpassword,confirmpassword:confirmpassword};
      const response = await fetch(Url+"vendor-portal-change-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ postdata}),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                                             Notifyalert('success',data.message)
 
          } else{
                                                                Notifyalert('error',data.message)

          }

           
       })
    }    
   
  };


   
 
  return (

    
      <div id="app">
            <Header title="Change Password" />

         <Sidebar />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
              <Pagetitle  activePage="Change Password"  />


                <div className="row match-height">
            <div className="col-md-7 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">Change Password</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Current Password <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  type="password" name="currentpassword" placeholder="Current Password"  
                onChange={(e) => handleUserInput(e )}
                required/>
                <p className="req-error-message">{currentpasswordreqerror}</p>
                        </div>
                        <div className="col-md-4">
                            <label>Password <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="password" name="newpassword" placeholder="New Password"  
                onChange={(e) => handleUserInput(e )}
                required/>
                 <p className="req-error-message">{newpasswordreqerror}</p>
                        </div>
                         

                         <div className="col-md-4">
                            <label>Confirm Password <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="password" name="confirmpassword" placeholder="Confirm Password"  
                onChange={(e) => handleUserInput(e )}
                required/>
                 <p className="req-error-message">{confirmpasswordreqerror}</p>
                        </div>
                         <div className="col-sm-12 d-flex justify-content-end">
                            <a  className="btn btn-primary me-1 mb-1" onClick={Updatepassword}>Update</a>
                         </div>
                       
 

                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>

                <Footer />
            </div>


         </div>

      </div>
  
  );
};
export default Changepassword;