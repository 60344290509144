import React, { useState } from "react";
 
import {vuserId,Url,CommonNotify,ProfilePictureOutput} from './../Api.js';

 

 
 

const Basic = () => {

 
 
  const [Profilepicture, setProfilepicture] = useState({ preview: ProfilePictureOutput, data: '' });

  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");



  const Updateprofile = async (e) => {
    e.preventDefault();
     setnotification("");

    try {

       let formData = new FormData()
      formData.append('profilepicture', Profilepicture.data)
      formData.append('id', vuserId)
       

      const response = await fetch(Url+"update-profile", {
        method: "POST",
        body:formData 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
             
         setnotificationtype('success')
                    setnotification(data.message)
          } else{
              //   
          }

           
       })
 
    } catch (error) {
   //   
    }
  };

 const handlePictureChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setProfilepicture(img)
  }


 
  return (
      
     <div id="app">
  







                        <form className="form form-horizontal">
                           <div className="form-body">
                              <div className="row">
                                 <div className="col-md-4">
                                    <label>Profile Picture</label>
                                 </div>
                                 <div className="col-md-8 form-group">
                                    <input className="form-control" onChange={handlePictureChange}  type="file" name="profilepicture" placeholder="Email"      
                                       required/>
                                    <br/>
                                    <img alt="Profile" className="img-responsive w-100px" src={Profilepicture.preview} />
                                 </div>
                                 <div className="col-sm-12 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updateprofile}  >Submit</button>
                                 </div>
                                 {
                                 (notification!=='') ?  (
                                 CommonNotify(notification,notificationtype)
                                 ) :'' 
                                 }
                              </div>
                           </div>
                        </form>




 
</div>
   
 
  );
};
export default Basic;