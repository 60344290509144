import React, { useState,useEffect } from "react";
 
import {SiteLogo,baseUrl,Url,CommonNotify} from './../Api.js';
 
 import { Link } from 'react-router-dom';
 
 
const Sidebar = ({activePage,activeChildPage}) => {
 
 

   const  [showServiceMenu,setshowServiceMenu]=useState(0);
   const  [showEarningMenu,setshowEarningMenu]=useState(0);
   const  [showJobMenu,setshowJobMenu]=useState(0);



const dashboardActive = (activePage === 'Dashboard') ? 'active':''; 
 const serviceActive = (activePage === 'Myservices'  ) ? 'active':''; 

 const jobActive = (activePage === 'Myjobs'  ) ? 'active':''; 

 const earningActive = (activePage === 'Myearnings'  ) ? 'active':''; 


const handleMenu = (e,type)=>{

    
 
switch (type) {
                case 'services':
                    setshowServiceMenu(1)
                    break;
                 case 'jobs':
                    setshowJobMenu(1)
                    break;
                      
                     case 'earnings':
                    setshowEarningMenu(1)
                    break;  

                 
 
            }            

}
const SetMenuActive = async() => {

    if(activePage  == 'services'){
     setshowServiceMenu(1);
    }
     if(activePage  == 'jobs'){
     setshowJobMenu(1);
    }

      if(activePage  == 'earnings'){
     setshowEarningMenu(1);
    }
     
      
}



    useEffect(() => {
   SetMenuActive();

  }, []);



  return (

 <div id="sidebar" className='active'>
   <div className="sidebar-wrapper active">
      <div className="sidebar-header">
         <img src={SiteLogo} alt="" srcSet={SiteLogo} />
      </div>
      <div className="sidebar-menu">
        <ul className="menu">
   <li className='sidebar-title'>Main Menu</li>




    <li className= {'sidebar-item '+dashboardActive} >
   <Link to={baseUrl+'dashboard'} className='sidebar-link amigo-link text-black text-black'>
   <i className="fa fa-home" width="20"></i> 
   <span>Dashboard</span>
   </Link>
   </li>  




 

<li className= {'sidebar-item '+serviceActive} >
   <Link to={baseUrl+'my-services'} className='sidebar-link amigo-link text-black text-black'>
   <i className="fa fa-home" width="20"></i> 
   <span>My Services</span>
   </Link>
   </li> 


   <li className= {'sidebar-item '+jobActive} >
   <Link to={baseUrl+'my-jobs'} className='sidebar-link amigo-link text-black text-black'>
   <i className="fa fa-home" width="20"></i> 
   <span>My Jobs</span>
   </Link>
   </li> 

   <li className= {'sidebar-item '+earningActive} >
   <Link to={baseUrl+'my-earnings'} className='sidebar-link amigo-link text-black text-black'>
   <i className="fa fa-home" width="20"></i> 
   <span>My Earnings</span>
   </Link>
   </li> 


 


  

</ul>

 



       
      </div>
      <button className="sidebar-toggler btn x amigo-button"><i className="fa fa-eyex"></i></button>
   </div>
</div>


  
  );
};
export default Sidebar;