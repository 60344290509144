import React ,{useEffect,useState} from "react";
 
import { Link, useNavigate } from "react-router-dom";

 import { SiteLogo, Siteinfo, frontUrl ,  Url, Notifyalert,requiredErrormessage } from '../Frontapi'
import {Lang} from "../Languages.js"

import {Aboutusurl , Faqurl, Contactusurl, Termsconditionsurl,Privacypolicyurl ,Refundpolicyurl ,Licenseagreementurl } from "../Siteurls.js"


// import  ('../../assets/css/frontend/style.css');


const Footer = ( ) => {


   var SiteData= Siteinfo[0]['sitedata']
    var ServiceData= Siteinfo[1]['servicedata']
  
   const [email, setEmail] = useState("");
const [emailreqerror, setemailreqerror] = useState("");


 const [btndisabled, setbtndisabled] = useState('btn-disabled');

const facebook = SiteData['facebook'];
const twitter = SiteData['twitter'];

const linkedin = SiteData['linkedin'];
const instagram = SiteData['instagram'];
const pinterest = SiteData['pinterest'];
const youtube = SiteData['youtube'];
const whatsapp = SiteData['whatsapp'];


const footer_content = SiteData['footer_content'];
const about = SiteData['meta_description'];




const services =   ServiceData;
  
  const handleUserInput = (e) => {


   var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'email':
            setEmail(value);
             setemailreqerror('');
             break;
       
    }

 
    if(email != ''  ){
       setbtndisabled('') 
    }else{
        setbtndisabled('btn-disabled') 
    }
 }





const Subscribe = async () => {
 

  var validationerror = 0;
  setemailreqerror('')
   if(email == ''){
    setemailreqerror(requiredErrormessage)
    validationerror=1;
  }
   
      if(validationerror == 0){ 
         var postdata = {
            email: email 
        };
        
            const response = await fetch(Url + "subscribe-user", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       Notifyalert('success',data.message)


                      

                     } else {
                        Notifyalert('error',data.message)
                      
                    }
                })
         


}
};
 

   

  return (
   <div class="main-footer">
<div class="container">
   <div class="footer">
      <div class="col-md-12 col-lg-12 col-xs-12 border-btm footer-widget paddingzero">
         <div class="col-md-4 col-sm-6 col-xs-12 footer-widget-01 paddingzero">
            <a href="Url/"><img src={frontUrl+'assets/images/frontend/logo/umy-footer-logo.png'} alt="amigoways" title="amigoways" class="amigoways-img img-responsive"/></a>
            <p>About Us</p>
            <span class="footer-about-us">{about}</span>
            <p>Follow Us</p>
            <ul class="footer_menu social_link">


            { (facebook) != ''  ?  
               <li> <a title="Facebook" href={facebook} target="_blank"><em class="fa fa-facebook"></em></a> </li>
               : '' }


               { (twitter) != ''  ?  
               <li> <a title="Twitter" href={twitter} target="_blank"><em class="fa fa-twitter"></em></a> </li>
                : '' }



                { (whatsapp) != ''  ?  
               <li> <a title="WhatsApp" href={whatsapp} ><em class="fa fa-whatsapp"></em></a> </li>: '' }


                { (instagram) != ''  ?  
               <li> <a title="Instagram" href={instagram} target="_blank"><em class="fa fa-instagram"></em></a> </li>: '' }

                { (youtube) != ''  ? 

               <li> <a title="Youtube" href={youtube} target="_blank"><em class="fa fa-youtube"></em></a> </li>
               : '' }



                { (pinterest) != ''  ? 
               <li> <a title="Pinterest" href={youtube} target="_blank"><em class="fa fa-pinterest"></em></a> </li>
                : '' }

                 { (linkedin) != ''  ? 
               <li> <a title="Linkedin" href={linkedin} target="_blank"><em class="fa fa-linkedin"></em></a> </li>
                : '' }



            </ul>
         </div>
         <div class="col-md-2 col-sm-6 col-xs-12 footer-widget-02 paddingzero">
            <p>Company</p>
            <a href={frontUrl} >Home</a>
            <a href={ 'about-us'}>About Us</a>
            <a href={'contact-us'}>Contact Us</a>
            <a href={'terms-conditions'}>Terms and Conditions</a>
            <a href={'privacy-policy'}>Privacy Policy</a>
         </div>
         <div class="col-md-2 col-sm-6 col-xs-12 footer-widget-03 paddingzero">
            <p>Services</p>


            {(services).map((service)=> ( 


                <a href="javascript:void(0)">{service.name}</a>

            ))}


          



         </div>
         <div class="col-md-4 col-sm-4 col-xs-4 footer-widget-04">
         <p>Download Our App</p>
            <span><img src={frontUrl+'assets/images/frontend/apple-store.png'}/></span>
            <span><img src={frontUrl+'assets/images/frontend/play-store.png'}/></span>    
         </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 paddingzero">
         <div class="copy-rights ">
         <div class="col-md-7 col-sm-7 col-xs-12 display-flex  paddingzero">
         <a>{footer_content}</a>
         </div>
         <div class="col-md-5 col-sm-5 col-xs-12 display-flex  paddingzero footer-terms-and-conditions">
         <a class="right"  href="https://www.amigoways.com" target="_blank">Designed and Developed by Amigoways Technologies</a>
      </div>
      </div>
      </div>
   </div>
</div>  
</div> 
  
  );
};
export default Footer;