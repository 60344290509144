import React from "react";
import {  Redirect,  useLocation,    useNavigate} from "react-router-dom";
   import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';



 import {SiteLogo,SiteFavicon} from './ImagesIcons.js';
 import {Getuserimage} from './GetDynamicImages.js';


import swal from 'sweetalert'


const GetfrontUrl_Url = async(param) => { 
 
if(window.location.origin === 'http://localhost:3003'){
   if(param === 'base_url'){
         return   'http://localhost/urbancompany/' ;
     }
    if(param === 'url'){
         return   'http://localhost:5090/' ;
    } 
    if(param === 'socekturl'){
         return   'http://localhost:5030/' ;
    }
     
}else if(window.location.origin === 'http://localhost'){

    if(param === 'base_url'){
         return   'http://localhost/' ;
     }
    if(param === 'url'){
         return   'http://localhost:5090/' ;
    } 
    if(param === 'socekturl'){
         return   'http://localhost:5030/' ;
    }
      


}else if(window.location.origin === 'https://www.umycompany.com'){
    if(param === 'base_url'){
         return   'https://www.umycompany.com/' ;
    }
    if(param === 'url'){
         return   'https://www.umycompany.com/api/?url=' ;
    } 

     if(param === 'socekturl'){
         return   '' ;
    }


}else if(window.location.origin === 'https://umycompany.com'){
    if(param === 'base_url'){
         return   'https://umycompany.com/' ;
    }
    if(param === 'url'){
         return   'https://www.umycompany.com/api/?url=' ;
    } 

     if(param === ''){
         return   '' ;
    }


}


}

const frontUrl=await GetfrontUrl_Url('base_url')
const Url=await GetfrontUrl_Url('url')

  
const UrlSplitter = (params) => {
         const location = useLocation();
            var url =location.pathname;
            var urls=url.split('/')
            return urls[params]
}

 
 
const Redirecthome = () => {
 
 if(localStorage.getItem('AmigouserId') !== '' && localStorage.getItem('AmigouserId') !== null ){
   window.location.href=frontUrl;
 
     return false;
   }
}

const Redirectlogin = () => {
   if(localStorage.getItem('AmigouserId') === '' || localStorage.getItem('AmigouserId') === null){
   window.location.href=frontUrl ;
   }
}




 


const CommonNotify = (data,type) => {
  
  if(type === 'success') {
    // NotificationManager.success(data);
  }
   if(type === 'error') {
   //  NotificationManager.error(data);
  }


   
 

  return (
    <div>   
    <div id="alert" className={type === 'success' ? 'alert alert-success' : 'alert alert-danger'} >{data} </div>
    </div>
    )
 
}

const checkEmptyUndefined=(data)=>{

if(typeof data === 'undefined' || data === ''){
  return false
}
return true

}
 


 const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

 const validPhoneRegex = RegExp(
        /^\+[1-9]{1}[0-9]{3,14}$/

);
 const validPasswordRegex = RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/



);


 const MobileNumberValidation=(text)=>{
 
   if(text.split('').length > 10 || ( /^\d+$/.test(text))  == false){

    return false
  }  

  return true;
 
}


const TextSpaceonlyValidation=(text)=>{



    if( /^[a-zA-Z\s]+$/.test(text)  == true &&  text.split('').length < 20 ){
            return true

    }
   return false;

}



 

const AmigouserId=localStorage.getItem('AmigouserId');
const Amigousertype=localStorage.getItem('Amigousertype');
const Amigousername=localStorage.getItem('Amigousername');

 

 

const Notifyalert = (type,message) => {

    if(type == 'error' ){

    document.getElementById("toast-notification").classList.add('toast-alert-danger')

    }else{
    document.getElementById("toast-notification").classList.add('toast-alert-success')

    }

    document.getElementById("toast-notification").style.display='block';
    document.getElementById("toast-body-notification").innerHTML=message;



    setTimeout(function(){
    document.getElementById("toast-notification").classList.add('fade-out')

    },500)
    setTimeout(function(){


    document.getElementById("toast-notification").style.display='none';

    document.getElementById("toast-notification").classList.remove('fade-out')
    document.getElementById("toast-notification").classList.remove('toast-alert-danger')
    document.getElementById("toast-notification").classList.remove('toast-alert-success')

    },3000)




    /* if(type == 'error' ){


  swal({
  title: message,
   timer: 2000,
   type: 'success',
  showCancelButton: false,
  showConfirmButton: false,
  buttons: false,
  icon: "error" 
 
})
   



        // NotificationManager.error(message);
    }else{ 
/*
document.getElementById("notify-alert-notification").innerHTML=message;

document.getElementById("notify-alert-notification").classList.add('fadein')
          document.getElementById("notify-alert-notification").classList.remove('fadeout')
          
       
          setTimeout(function(){
 document.getElementById("notify-alert-notification").classList.remove('fadein')
          document.getElementById("notify-alert-notification").classList.add('fadeout')
            },2000)
 */
/*
        swal({
  title: message,
   timer: 2000,
   type: 'success',
  showCancelButton: false,
  showConfirmButton: false,
  buttons: false,
  icon: "success" 
 
})

     //swal(" ", message, "success");

     

         // NotificationManager.success(message);
          setTimeout(function(){
               //   window.location.reload()
          },500)
    }
*/


  
} 


const TriggerLogout = (data) => {

localStorage.removeItem('AmigouserId');
localStorage.removeItem('Amigousertype');

localStorage.removeItem('Amigousername');
localStorage.removeItem('Amigoreferralcode');

      
}



const SetNotificationAfterName=(name,message,noneed='')=>{
var element= document.getElementsByName(name)[0] ;

                 if(noneed != '' && noneed != null && typeof noneed != 'undefined'){

                
                }else{
                    var requiredelement = document.createElement("div");
                requiredelement.classList.add('notify-input-text');
                requiredelement.innerHTML=   message
                     element.after(requiredelement);
                }
               
                 element.focus();


}

const RemoveNotificationAfterName=()=>{
if( document.querySelector(".notify-input-text")){
     if(document.getElementsByClassName('notify-input-text')[0]){
  document.getElementsByClassName('notify-input-text')[0].remove();

             } 
}
  if( document.querySelector(".notify-input-missing")){

  document.getElementsByClassName('notify-input-missing')[0].classList.add('mb-25');
  document.getElementsByClassName('notify-input-missing')[0].classList.remove('notify-input-missing');
}

   
}   
 

const CheckUserExists = async() => {   


var postdata={  userid:AmigouserId}
var result =  await  fetch(Url + "check-user", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postdata
        }),
    }).then(async (response) => response.json())
    .then(async  (data) => {
    
     if(!data.success){
       TriggerLogout(); 
    }
         


         
         
    })

//
}
await CheckUserExists()


  

const showLoader=async(status)=>{

if(status == true){
  //    document.getElementsByClassName('page-loader-dynamic')[0].classList.remove('display_none');

}
 
setTimeout(function(){

   //    document.getElementsByClassName('page-loader-dynamic')[0].classList.add('display_none');

},1000)

 



} 

const requiredErrormessage = 'This field is required'
 


 const Getsettings = async (field) => {
   
     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            return data.data;
             

            
         
          }  

           
       })
if(field == 'all'){
return response
} 
 
return response[field];
  

 } 




 const Getsiteinfo = async () => {
   
     var postdata={getdata:1};

     const response = await fetch(Url+"get-site-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            return data.data;
             

            
         
          }  

           
       })
 return response
 
 
   

 }



const Siteinfo =await Getsiteinfo()


const Sitename =await Getsettings('sitename')
const Settingsdata =await Getsettings('all')
const Siteemail =await Getsettings('email')
const Sitephone =await Getsettings('phone')
const Siteaddress =await Getsettings('address')



 
export {UrlSplitter,Settingsdata,Sitename, Siteemail ,Sitephone, Siteinfo, Siteaddress,TextSpaceonlyValidation,MobileNumberValidation,Amigousername , AmigouserId, requiredErrormessage, frontUrl,Url  , Redirecthome , Redirectlogin , CommonNotify, Notifyalert, checkEmptyUndefined,SetNotificationAfterName , RemoveNotificationAfterName,validEmailRegex, SiteFavicon,SiteLogo,TriggerLogout  }
