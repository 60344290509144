import React, { useState } from "react";
 
import {vuserId,Url,CommonNotify,ProfilePictureOutput} from './../Api.js';

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";

import Footer from "../includes/Footer";

 
 import Basic from "./Basic";
import Address from "./Address";
import Payment from "./Payment";
 

const Profile = () => {

 
 
  const [Profilepicture, setProfilepicture] = useState({ preview: ProfilePictureOutput, data: '' });

  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");



  const Updateprofile = async (e) => {
    e.preventDefault();
     setnotification("");

    try {

       let formData = new FormData()
      formData.append('profilepicture', Profilepicture.data)
      formData.append('id', vuserId)
       

      const response = await fetch(Url+"update-profile", {
        method: "POST",
        body:formData 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
             
         setnotificationtype('success')
                    setnotification(data.message)
          } else{
              //   
          }

           
       })
 
    } catch (error) {
   //   
    }
  };

 const handlePictureChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setProfilepicture(img)
  }


 
  return (
      
     <div id="app">
   <Header  title="My Profile"    />
   <Sidebar />
   <div id="main">
      <Navbar />
      <div className="main-content container-fluid">
         <div className="page-title">
            <div className="row">
               <div className="col-12 col-md-6 order-md-1 order-last">
                  <h3>My Profile</h3>
               </div>
               <div className="col-12 col-md-6 order-md-2 order-first">
                  <nav aria-label="breadcrumb" className='breadcrumb-header'>
                     <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Settings</a></li>
                        <li className="breadcrumb-item active" aria-current="page">My Profile</li>
                     </ol>
                  </nav>
               </div>
            </div>
         </div>
         <div className="row match-height">
            <div className="col-md-12 col-12 mx-auto">
               <div className="card">
                  <div className="card-header">
                     <h4 className="card-title">My Profile</h4>


                       <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                        <a className="nav-link active card-title" id="basic-tab" data-bs-toggle="tab" href="#basic" role="tab" aria-controls="home"
                        aria-selected="true">Basic</a>
                        </li>
                        <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="address-tab" data-bs-toggle="tab" href="#address" role="tab" aria-controls="profile"
                        aria-selected="false">Address</a>
                        </li>
                        <li className="nav-item" role="presentation">
                        <a className="nav-link card-title" id="payment-tab" data-bs-toggle="tab" href="#payment" role="tab" aria-controls="contact"
                        aria-selected="false">Payment Details</a>
                        </li>

                         


                        </ul>
                  </div>
                  <div className="card-content">
                     <div className="card-body">



                     <div className="tab-content" id="myTabContent">


                        <div className="tab-pane fade show active" id="basic" role="tabpanel" aria-labelledby="basic-tab">
                        <Basic />
                        </div>



                        <div className="tab-pane fade  " id="address" role="tabpanel" aria-labelledby="address-tab">
                        <Address />
                        </div>



                        <div className="tab-pane fade  " id="payment" role="tabpanel" aria-labelledby="payment-tab">
                        <Payment />
                        </div>

                    </div>








 






                     </div>
                  </div>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   </div>
</div>
   
 
  );
};
export default Profile;