import React, {    useState,useEffect} from "react";
 import { Link, useNavigate } from "react-router-dom";

import { Siteinfo , TextSpaceonlyValidation,Siteemail ,Sitephone, Siteaddress, MobileNumberValidation,requiredErrormessage, frontUrl,Notifyalert, Url,Redirectlogin, AmigouserId, Amigousername , validEmailRegex } from '../Frontapi.js'; 
  
  import {Lang} from "../Languages.js"

 
import Header from '../includes/Header';
 import Navbar from '../includes/Navbar';


 import Footer from '../includes/Footer';

const Contactus = () => {
 
   var SiteData= Siteinfo[0]['sitedata']


const contactemail = SiteData['email'];
const contactphone = SiteData['phone'];
const contactaddress = SiteData['address']+','+SiteData['city']+','+SiteData['state']+','+SiteData['country']+','+SiteData['postalcode'];


 

const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [mobile, setMobile] = useState("");
const [subject, setSubject] = useState("");

 const [message, setMessage] = useState("");

 const [btndisabled, setbtndisabled] = useState('btn-disabled');


 



const [namereqerror, setnamereqerror] = useState("");
const [emailreqerror, setemailreqerror] = useState("");
const [mobilereqerror, setmobilereqerror] = useState("");
const [subjectreqerror, setsubjectreqerror] = useState("");
const [messagereqerror, setmessagereqerror] = useState("");

 const [emailvaliderror, setemailvaliderror] = useState("");





  const handleUserInput = async(e) => {
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
             case 'name':
               ( TextSpaceonlyValidation(value) == true ) ?  setName(value) :  setnamereqerror('');

            // setName(value);
             setnamereqerror('');
              break;

              case 'email':
             setEmail(value);
             setemailreqerror('');
             break;

              case 'mobile':

               

                  setMobile(value)
             
             setmobilereqerror('');
             break;

             case 'subject':
             setSubject(value);
             setsubjectreqerror('');
             break;

             case 'message':
             setMessage(value);
             setmessagereqerror('');
             break;
          
            }


 


        if(name != '' && email != '' && mobile != ''&& subject != ''&& message != ''){
             setbtndisabled('') 
        }else{
             setbtndisabled('btn-disabled') 
        }


    }



  
 const CreateEnquiry = async (e) => {

 


var validationerror = 0;
setnamereqerror()
setemailreqerror()
setmobilereqerror()
setsubjectreqerror()
setmessagereqerror()

    

   if(name == ''){
    setnamereqerror(requiredErrormessage)
    validationerror=1;
  }
    


  if(email == ''){
    setemailreqerror(requiredErrormessage)
    validationerror=1;
  }
 if(!validEmailRegex.test(email) && email!= ''){
  setemailvaliderror('Invalid Email')
  validationerror=1;
 }
    if(mobile == ''){
    setmobilereqerror(requiredErrormessage)
    validationerror=1;
  }
  if(mobile != '' && MobileNumberValidation(mobile) == false ){
setmobilereqerror("Invalid Mobile Number")
    validationerror=1;
  }

   if(subject == ''){
    setsubjectreqerror(requiredErrormessage)
    validationerror=1;
  }
    if(message == ''){
    setmessagereqerror(requiredErrormessage)
    validationerror=1;
  }
 
 
  

      if(validationerror == 0){ 
         var postdata = {
            name:name,
            mobile: mobile,
             email:email,
             subject:subject,
             message:message
         };
        
            const response = await fetch(Url + "create-new-inquiry", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       Notifyalert('success',data.message)
 
 
                     } else {
                        Notifyalert('error',data.message)
                      
                    }
                })
         


}




        }
      

 
  return (
   <div >
   <Header pageInfo="Contactus" />
   



   <div className="container top-bottom contact-form">
      <div className="center-align">
          <h3 className="section-title">Contact Us</h3>
          <h2 className="section-title">Get In Touch With Us!</h2>
          <p>We are here to help you.</p>
           </div>
                <div className="col-xs-12 col-md-12 col-lg-12 col-lg-12 field-row paddingzero">
                 <div className="col-xs-12 col-md-6 col-lg-6 col-lg-6">
                   <label className="field_title" for="name">Name <span className="req">*</span></label>
                   <div className="form_input">
                     <input autocomplete="off" type="text"  value={name}  onChange={(e) => handleUserInput(e)}  name="name"   className="form-control" id="name" placeholder="Name" />
                     <p>{namereqerror}</p>
                   </div>
                 </div>
                 <div className="col-xs-12 col-md-6 col-lg-6 col-lg-6">
                   <label className="field_title" for="email">Email ID <span className="req">*</span></label>
                   <div className="form_input">
                     <input autocomplete="off" type="text" name="email" value={email} onChange={(e) => handleUserInput(e)}   className="form-control" id="email" placeholder="Email ID"/>
                     <p>{emailreqerror}</p>
                   </div>
                 </div>
               </div>
               <div className="col-xs-12 col-md-12 col-lg-12 col-lg-12 field-row paddingzero">
                 <div className="col-xs-12 col-md-6 col-lg-6 col-lg-6">
                   <label className="field_title " for="phone">Phone Number <span className="req">*</span></label>
                   <div className="form_input col-lg-12 col-md-12 col-xs-12 paddingzero">
                       <input autocomplete="off" type="text" name="mobile" value={mobile} onChange={(e) => handleUserInput(e)}   className="form-control user_phone_no number_copy_paste" id="phone_number" placeholder="Phone Number"/>
                       <p>{mobilereqerror}</p>
                   </div>
                 </div>
                 <div className="col-xs-12 col-md-6 col-lg-6 col-lg-6">
                     <label className="field_title" for="subject">Subject <span className="req">*</span></label>
                     <div className="form_input" id="state_result">
                       <input autocomplete="off" type="text" name="subject" value={subject}   onChange={(e) => handleUserInput(e)}   className="form-control" id="subject" placeholder="Subject"/>
                       <p>{subjectreqerror}</p>
                     </div>
                 </div>
               </div>
               <div className="col-xs-12 col-md-12 col-lg-12 col-lg-12 field-row paddingzero">
                 <div className="col-xs-12 col-md-12 col-lg-12 col-lg-12">
                     <label className="field_title" for="user_msg">Message <span className="req">*</span></label>
                     <div className="form_input">
                       <textarea name="message" rows="10" autocomplete="off" onChange={(e) => handleUserInput(e)} className="form-control" id="user_msg" placeholder="Message">{message}</textarea>
                       <p>{messagereqerror}</p>
                     </div>
                 </div>
               </div>
               <div className="col-lg-12 col-md-12 col-xs-12 center-align field-row">
                 <button onClick={CreateEnquiry} type="submit" className={'amigoways-btn ' + btndisabled } tabindex="10"><span>Contact Us</span></button>
               </div>
               <div className="col-xs-12 col-md-12 col-sm-12 col-lg-12">
          <ul className="contact-us-details">
            <div className="col-md-4 col-sm-4 col-xs-12 margin-top-2">
          <li>
            <div className="media-left"><em className="fa fa-map-marker">&nbsp;</em></div>
            <div className="media-body"><h5>{contactaddress}</h5><p> </p></div>
          </li>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12 margin-top-2">
          <li>
            <div className="media-left"><em className="fa fa-phone">&nbsp;</em></div>
            <div className="media-body"><h5>{contactphone}</h5> </div>
          </li>
        </div>
        
        <div className="col-md-4 col-sm-4 col-xs-12 margin-top-2">
          <li>
            <div className="media-left"><em className="fa fa-envelope">&nbsp;</em></div>
            <div className="media-body"><h5>General Support &amp; Sales</h5>{contactemail}</div>
          </li>
        </div>
        </ul>
        </div>
             
   </div>



   <Footer />
</div>

 
  );
};
export default Contactus;