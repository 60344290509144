import React, {useEffect, useState } from "react";
 
 import {TextSpaceonlyValidation,Notifyalert,requiredErrormessage,Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allowadminusers_view ,
    Allowadminusers_add ,
    Allowadminusers_edit ,
    Allowadminusers_delete 
} from './../../Permissions.js';

 

const Addadminuser = () => {

 
 
 
const[name, setName] = useState(""); 
const[username, setUsername] = useState(""); 
const[email, setEmail] = useState(""); 
const[password, setPassword] = useState(""); 
const[confirmpassword, setConfirmPassword] = useState(""); 
const[status, setStatus] = useState(1); 

const[role, setRole] = useState(""); 
const [rolesList, setrolesList] = useState([]);


const [namereqerror, setnamereqerror] = useState("");
const [emailreqerror, setemailreqerror] = useState("");

const [usernamereqerror, setusernamereqerror] = useState("");
const [passwordreqerror, setpasswordreqerror] = useState("");
const [confirmpasswordreqerror, setconfirmpasswordreqerror] = useState("");
const [rolereqerror, setrolereqerror] = useState("");


const [emailexistserror, setemailexistserror] = useState("");
const [usernameexistserror, setusernameexistserror] = useState("");

const [emailexistsvalidation , setemailexistsvalidation] = useState("0");
const [usernameexistsvalidation , setusernameexistsvalidation] = useState("0");


const [emailvaliderror, setemailvaliderror] = useState("");





 
const[adminuserid] = useState(UrlSplitter(4));


 
 
 const Getuser = async (e) => {
   
     var postdata={_id:adminuserid};

     const response = await fetch(Url+"getadminuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              
              var userdata=data.data;
                setName(userdata.name);
                setUsername(userdata.username);
                setEmail(userdata.email);
                 setStatus(userdata.status);
               setRole(userdata.role);
          
          } else{
              //   
          }

           
       })


 
 } 


 
  const Updateuser = async (e) => {
setnamereqerror('');
    setusernamereqerror('');
    setemailreqerror('');

 
    var validationerror = 0;
 
    if(checkEmptyUndefined(name) == false){
        setnamereqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(username) == false){
        setusernamereqerror(requiredErrormessage)
        validationerror=1;
    }  
    if(checkEmptyUndefined(email) == false){

        setemailreqerror(requiredErrormessage)
        validationerror=1;
    }
    if(checkEmptyUndefined(email) == true && validEmailRegex.test(email) == false){
        setemailreqerror("Invalid Email")
        validationerror=1; 
    }
    if(checkEmptyUndefined(role) == false){

        setrolereqerror(requiredErrormessage)
        validationerror=1;
    }




    if(typeof adminuserid == 'undefined' || adminuserid == ''){

         if(checkEmptyUndefined(password) == false){

            setpasswordreqerror(requiredErrormessage)
            validationerror=1;
        }

        if(checkEmptyUndefined(confirmpassword) == false){

            setconfirmpasswordreqerror(requiredErrormessage)
            validationerror=1;
        }
          if(password != confirmpassword){
             setconfirmpasswordreqerror("Password and Confirm Password Should match")
             validationerror=1;
        }




    }else{

        if(password != ''){

        if(password != confirmpassword){
             setconfirmpasswordreqerror("Password and Confirm Password Should match")
             validationerror=1;
        }


    }
    }
    


 
     if(validationerror == 0){ 

  

       var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;
        
      var postdata={_id:adminuserid ,name :name,username:username,email:email,role:role ,status:statusupdate , password:password};
 
      const response = await fetch(Url+"updateadminuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
       
                                                        Notifyalert('success',data.message)

          } else{
                                                  Notifyalert('error',data.message)

          }

           
       })
      }
  };


const handleUserInput = (e) => {
     var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        case 'name':
              (TextSpaceonlyValidation(value) == true ) ?  setName(value) : setnamereqerror('');
             
             
            break;
        case 'email':
            setEmail(value);
            setemailreqerror('');
            break;
        case 'username':
            setUsername(value);
             setusernamereqerror('');
            break;
        case 'password':
            setPassword(value);
            setpasswordreqerror('')
            break;  
        case 'confirmpassword':
            setConfirmPassword(value);
            setconfirmpasswordreqerror('')
            break;  
      
        case 'role':
            setRole(value);
            setrolereqerror('')
            break;   
         case 'status':
            setStatus(value);
            break;  
        default:
            break;      
    }
 }


 const GetRoleslist = async ( ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

 
      var postdata={'currentpage':1,'perpage':10000};

        const response = await fetch(Url+"getroles", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           setrolesList(data.data );
         
         
          } else{
                 
          }

           
       })


 
  };
    
useEffect(() => {
    GetRoleslist();
   Getuser(adminuserid);

if(typeof adminuserid != 'undefined' && adminuserid !=''){
    checkpermissionredirect(Allowadminusers_edit)
}else{
    checkpermissionredirect(Allowadminusers_add)

}

  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof adminuserid !== 'undefined'?"Edit User":"Add User"} />

         <Sidebar activePage="Adminusers"  activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit Admin user":"Add Admin user"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit User":"Add User"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Name <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={name}  type="text" name="name" placeholder="Name"  
                onChange={(e) => handleUserInput(e)}
                required/>
                                                    <p className="req-error-message">{namereqerror}</p>

                        </div>
                        <div className="col-md-4">
                            <label>Username <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="text" name="username" placeholder="Username" value={username} 
                onChange={(e) => handleUserInput(e)}
                required/>
                                    <p className="req-error-message">{usernamereqerror}</p>

                        </div>
                         
                        <div className="col-md-4">
                            <label>Email <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="text" name="email" placeholder="Email" value={email} 
                onChange={(e) => handleUserInput(e)}
                required/>
                                                    <p className="req-error-message">{emailreqerror}</p>

                        </div>


                        <div className="col-md-4">
                            <label>Password</label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="password" name="password" placeholder="Password" value={password} 
                onChange={(e) => handleUserInput(e)}
                required/>
                                         <p className="req-error-message">{passwordreqerror}</p>

                        </div>

                        <div className="col-md-4">
                            <label>Confirm Password</label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="password" name="confirmpassword" placeholder="Confirm Password" value={confirmpassword} 
                onChange={(e) => handleUserInput(e)}
                required/>
                       <p className="req-error-message">{confirmpasswordreqerror}</p>

                        </div>

                        <div className="col-md-4">
                            <label>Role <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="role"  onChange={(e) => handleUserInput(e)}> 
                            <option value="">Choose</option>
                            {rolesList.map((rolelist) => (
                                <option value={rolelist._id}  selected={rolelist._id == role ? 'selected' :''}  >{rolelist.name}</option>
                             ))}
                            </select>
                           
                                 <p className="req-error-message">{rolereqerror}</p>

                        </div>
                        
                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>

                         <div className="col-sm-12 d-flex justify-content-end">
                            <a type="submit" className="btn btn-primary me-1 mb-1" onClick={Updateuser}>Submit</a>
                         </div>
                         


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Addadminuser;