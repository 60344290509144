import React, {
    useState,
    useEffect
} from "react";
import {
    Link,
    useNavigate
} from "react-router-dom";
import {
    TextSpaceonlyValidation,
    MobileNumberValidation,
    requiredErrormessage,
    frontUrl,
    Notifyalert,
    Url,
    Redirectlogin,
    AmigouserId,
    Amigousername,
    validEmailRegex
} from '../Frontapi.js';
import Header from '../includes/Header';
import Navbar from '../includes/Navbar';
import Footer from '../includes/Footer';


const Termsconditions = () => {


        const [title, setTitle] = useState('')
        const [content, setContent] = useState('')


        const Getcms = async () => {
            var postdata = {
                cmsurl: 'terms-conditions'
            }
            const response = await fetch(Url + "get-cms-data", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setTitle(data.data.name)
                        setContent(data.data.content)
                    } else {}
                })
        }


        useEffect(() => {
            Getcms();
        }, [title, content]);


 
  return (
   <div id="app">

    <Header pageInfo="Termsconditions" />

    <div className="container top-bottom">
   <div className="col-md-12 col-sm-12 col-xs-12 about-us">
      
         <h5>{title}</h5>
   
         <div dangerouslySetInnerHTML={{__html:  content}} />

         
    </div>
</div>
 
 
    <Footer />


    </div>

     


 
  );
};
export default Termsconditions;